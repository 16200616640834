import { actionIcDashboard24px } from '@ey-xd/motif-react/assets/icons';
import { SIDEBAR, WIDGET_OPTIONS } from './app.labels';
import {
    customCIMIcFileDownload24px,
    fileIcFileDownload24px,
    maxIcon,
    miniIcon
} from './assets/icons';

export const CLASS_HIGHCHARTS_LEGEND: string = 'highcharts-legend-custom';

export const TENANT = '5b973f99-77df-4beb-b27d-aa0c70b8482c';
export const CLIENT_ID = 'fdcaf0d2-b1e3-4a37-9f26-f42a4d4245c5';
export const X_Tenant_Id = '56D853CF-C1A8-4144-94BE-B7FAD00BFADE';
export const BASE_API_URL = 'https://bff-cim-qa.sbp.eyclienthub.com/apigatewayqa';
export const REACT_APP_URL = 'https://cim-qa.sbp.eyclienthub.com/';

export const HEADER = {
    CONTACTUS: {
        EMAIL: 'eyclimatescenariomodelling@au.ey.com',
        SUBJECT: ''
    },
    HELP: {
        LINK:
            'https://sites.ey.com/sites/DS_SaT/Pages/esg-economic-and-business-case-modelling.aspx'
    },
    LOGOUT: {
        CONFIRMATION_TEXT: 'Are you sure want to logout?'
    }
};

export const ENGAGEMENT_MANAGRMENT_URL = 'https://discovercontent.ey.net/knd24092efwhdm40';
export const sideBarMenuList = [
    {
        id: 1,
        item: SIDEBAR.dashboard,
        icon: actionIcDashboard24px,
        keyName: SIDEBAR.dashboard,
        path: '/',
        role: '',
        relativeRoutes: ''
    },
    {
        id: 2,
        item: SIDEBAR.download,
        icon: customCIMIcFileDownload24px,
        keyName: SIDEBAR.download,
        path: '/',
        role: '',
        relativeRoutes: ''
    }
];

export const widgetOptions = [
    {
        title: WIDGET_OPTIONS.maximize,
        icon: maxIcon
    },
    {
        title: WIDGET_OPTIONS.minimize,
        icon: miniIcon
    }
];

export const DFAULT_WIDGET_HIEGHT = '500px';

export const EMISSION_INTENSITY_BY_SECTOR_CONSTANTS = {
    height: '715px',
    flexType: 'flex-3'
};

export const DOWNLOAD_WIDGET_OPTION = [
    {
        title: WIDGET_OPTIONS.download,
        icon: fileIcFileDownload24px
    }
];

export const CHART_FONT_SIZE_12PX = '12px';
export const CHART_FONT_SIZE_14PX = '14px';
export const CHART_FONT_SIZE_13PX = '13px';
export const SOLID_LINE_STYLE = 'Solid';
export const MARKER_SYMBOL_CIRCLE = 'circle';
export const AREA_CHART_TYPE = 'area';
export const COLUMN_CHART_TYPE = 'column';
export const BILLON_SMALL_CAP = 'bn';
export const DATA_BY_NAME = 'name';
export const DATA_BY_YEAR = 'year';
export const FILTER_BY_REGION = 'region';
export const FILTER_BY_SECTOR = 'sector';

export const PRODUCTION = 'production';
export const APP_HISTORY: any = { history: null };
export const FONT_WEIGHT: string = '400';
export const OTHERS_NAME = 'Others';
export const PIE_TWO_COLUMN = 70;
export const PIE_THREE_COLUMN = 40;

export const REG_FOSSIL_FUEL = 'fossilFuel';
export const REG_FF_REMAINING_TOTAL_FUEL = 'remainingTotalFuel';
export const SCE_FF_PERCENTAGE_VALUE = 'fossilFuelPercentageValue';
export const SCE_FF_OTHER_FUEL_PERCENTAGE = 'otherFuelPercentageValue';

export const REG_CARBON_TAX = 'carbonTax';
export const REG_CARBON_REMAINING_TAX = 'remainingTax';
export const SCE_CT_PERCENTAGE = 'carbonTaxPercentageValue';
export const SCE_CT_OTHER_PERCENTAGE = 'otherTaxPercentageValue';

export const TABLE_PROPERTIES = {
    regionHeader: 'Region',
    scenarioHeader: 'Scenarios',
    sectorHeader: 'Sector',
    detailsText: 'details',
    tableHeight: 'auto',
    dataTestId: 'maximized-table',
    firstColWidthRegView: 250,
    SecondColWidthRegView: 300,
    firstColWidthSceView: 150,
    defaultColWidth: 180,
    regionFrom: 'Region from',
    regionTo: 'Region to',
    firstColWidthSector: 400,
    variable: 'Variable',
    inputSector: 'Input Sector',
    scenario: 'Scenario',
    sectorsHeader: 'Sectors'
};

export const Q_QUERY_KEY: string = 'q';
export const SPLINE_CHART_TYPE = 'spline';
export const APP_START_YEAR = '2020';
export const RICH_TEXT_EDITOR = {
    DEFAULT_HEIGHT: 200,
    DEFAULT_PLACE_HOLDER: 'Please type your content here...',
    EDITOR_MAX_CHARACTER_LIMIT: 1000,
    READMORE_PREVIEW_CHARACTER_LIMIT: 375
};
export const HTTP_METHOD = { POST: 'POST', GET: 'GET' };
export const PANEL_TITLE_ERROR_MESSAGE = 'Title should not be empty and cannot contain / \\ #';
export const PANEL_TEXT_EDITOR_ERROR_MESSAGE = 'Please Add Panel Description Here.';
export const SUB_PANEL_TITLE_ERROR_MESSAGE = 'Please Add Sub-Panel Title Here.';
export const SUB_PANEL_TEXT_EDITOR_ERROR_MESSAGE = 'Please Add Sub-Panel Description Here.';
export const SUB_PANEL_TEMPERATURE_ERROR_MESSAGE = 'Temperature value missing';
export const SUB_PANEL_YEAR_ERROR_MESSAGE = 'Enter valid Year';
export const PANEL_TITLE = 'Panel Title';
export const SUB_PANEL_TITLE = 'Sub-Panel Title';
export const ENTER_VALUE = 'Enter value';
export const GVA_BY_SEACTOR = 'sector';
export const OUTPUT_BY_SEACTOR = 'sector';
export const IMAGE_ERROR_MESSAGE =
    'Image file size should be under 1 MB. Supported formats are jpg, jpeg, or png.';
export const IMAGE_FILE_NAME_ERROR_MESSAGE = 'Image file name must be 50 characters or less!';
export const IMAGE_FILE_NAME_SPECIAL_CHAR_ERROR_MESSAGE =
    'Invalid File Name. It should not contain special characters';
export const IMAGE_FILE_NAME_DOT_ERROR_MESSAGE =
    'Image file name should not contain more than one dot!';
export const IMAGE_FILE_TYPE_ERROR_MESSAGE = 'Supported formats are jpg, jpeg, or png.';
export const IMAGE_FILE_SIZE_ERROR_MESSAGE = 'Image file size should be under 1 MB!';
export const IMAGE_TYPE_JPE = 'jpg';
export const IMAGE_TYPE_JPEG = 'jpeg';
export const IMAGE_TYPE_PNG = 'png';
export const IMAGE_UPLOAD_SIZE_IN_MB = 1000000;
export const IMAGE_FILE_NAME_REGEX = /^[a-zA-Z0-9\s\-_]+(\.)[a-zA-Z]*$/;
export const NO_DATA_FOUND_EYENGAGEMENT_OWNER =
    'No data is available for the Overview Tab. Request you to please enable the panel to view data in the Overview Section.';
export const NO_DATA_AVAILABLE = 'No data available';
export const IMAGE_UPLOAD_BUTTONS = {
    CHANGE_IMAGE_BUTTON_TEXT: 'Change Image',
    UPLOAD_IMAGE_BUTTON_TEXT: 'Upload Image',
    DELETE_IMAGE_BUTTON_TEXT: 'Delete Image'
};
